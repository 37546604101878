import { cloneDeep } from 'lodash';
import { AiBlockSchema } from './aiBlock';
import { AttachmentSchema } from './attachment';
import { BotSchema } from './bot';
import { CampaignMeta } from './campaign';
import { BaseModelSchema, BaseRelationshipSchema } from './defaults';
import { DynamicContentSchema } from './dynamicContent';
import { TeamSchema } from './team';

export interface SimpleCampaignSchema {
  aiRepliesEnabled: boolean;
  bots: BotSchema[];
  canSplitTest: boolean;
  id: number;
  meta: CampaignMeta | null;
  name: string;
  campaignPlaybookId?: number | null;
}

export interface ConversationTopicSchema extends BaseModelSchema {
  campaigns: SimpleCampaignSchema[];
  canBePublished: boolean;
  draftCampaignId: number | null;
  draftSavedAt: string | null;
  hasLiveVersion: boolean;
  hasUnpublishedChanges: boolean;
  lastPublishedAt: string | null;
  mainSequence: DecoratedSequenceSchema;
  name: string;
  noOfLeads: number;
  sequences: DecoratedSequenceSchema[];
  teamId: number;
}

export interface SequenceSchema extends BaseModelSchema {
  conversationTopicId: number;
  conversationTopicVersionId: number;
  conversationTopicVersionSettings: {
    rfiToQualified?: boolean;
  };
  displayName: string;
  durationType: string;
  hasReferralSnippets: boolean;
  highlightedAt: string;
  isValid: boolean;
  qualificationRule: QualificationRuleSchema | null;
  sequence: DecoratedSequenceSchema;
  sequenceTouchPoints: DecoratedSequenceTouchPointSchema[];
  snippets: {
    referralOptIn: string;
  };
  team: TeamSchema;
  terminalState: string;
  topicRuleId: string;
  triggerOnDuration: number;
}

export interface DecoratedSequenceSchema extends SequenceSchema {
  didUpdate: boolean;
  hasChildren: boolean;

  hasFollowUpEmail: boolean;
  isContinue: boolean;
  isNotInterested: boolean;
  isNotInterestedNow: boolean;
  isNotTerminal: boolean;
  isQualified: boolean;
  isReferral: boolean;
  isRoot: boolean;
  isTerminal: boolean;
  shouldHighlight: boolean;
}
export interface DecoratedConversationTopicSchema
  extends Omit<ConversationTopicSchema, 'sequences' | 'mainSequence'> {
  mainSequence: DecoratedSequenceSchema;
  mergeTags: { attributeName: string; modelName: string }[];
  sequences: DecoratedSequenceSchema[];
}
type SequenceTouchPointAttributesSchema = {
  durationType: string;
  sequenceId: number | string;
  sequenceTouchPointTemplates: SequenceTouchPointTemplateSchema[];
  triggerOnDuration: number;
};
export interface SequenceTouchPointSchema extends SequenceTouchPointAttributesSchema {
  id: number;
}
export interface SequenceTouchPointAPISchema {
  attributes: SequenceTouchPointAttributesSchema;
  id: number;
  relationships: BaseRelationshipSchema[];
  type: 'sequence_touch_points';
}
export interface SeqTpTemplateCreatePayloadSchema {
  attachments: TouchPointEditorAttachmentInterface[];
  body: string;
  deliveryType: 'new' | 'reply';
  isActive: boolean;
  subject: string;
}
export interface TouchPointCreatePayloadSchema {
  durationType: string;
  templates: SeqTpTemplateCreatePayloadSchema[];
  triggerOnDuration: number;
}

export interface DecoratedSequenceTouchPointSchema extends SequenceTouchPointSchema {
  isSendImmediately: boolean;
  templates: SequenceTouchPointTemplateSchema[];
}
export interface QualificationRuleSchema extends BaseModelSchema {
  description: string;
  qualificationRuleItems: QualificationRuleItemSchema[] | null;
  ruleType: string;
  sequenceId: number;
}
export interface QualificationRuleItemSchema extends BaseModelSchema {
  qualificationRuleId: number;
  sequence: DecoratedSequenceSchema;
  topicRule: TopicRuleSchema;
}
type SequenceTouchPointTemplateAttributesSchema = {
  attachments: { attachment: AttachmentSchema; id: number }[];
  body: string;
  deliveryType: 'reply' | 'new';
  dynamicContents: DynamicContentSchema[];
  isActive: boolean;
  sequenceTouchPointTemplateAttachments: SequenceTouchPointTemplateAttachmentSchema[];
  snippet: string;
  subject: string;
  aiBlocks?: AiBlockSchema[]; // TODO: make non-optional once BE is ready
};
export interface SequenceTouchPointTemplateSchema
  extends SequenceTouchPointTemplateAttributesSchema {
  id: SequenceTouchPointTemplateAPISchema['id'];
}
export interface SequenceTouchPointTemplateAPISchema {
  attributes: SequenceTouchPointTemplateAttributesSchema;
  id: number;
  relationships: BaseRelationshipSchema[];
  type: 'sequence_touch_point_templates';
}
export interface SequenceTouchPointTemplateAttachmentSchema {
  attachment: AttachmentSchema;
  id: number;
}
export interface MergeTagValueSchema {
  attribute: string;
  value: string | null;
  excludeIfNextState?: string[];
  fallbackValue?: string;
  id?: string;
}
export const conversationTopicDecorator = (
  c: ConversationTopicSchema
): DecoratedConversationTopicSchema => {
  let sequences: DecoratedSequenceSchema[] = [sequenceDecorator(c.mainSequence)];
  sequenceEnumerator(c.mainSequence, sequences);
  let ct = cloneDeep(c) as DecoratedConversationTopicSchema;
  ct.sequences = sequences;
  ct.mainSequence = sequenceDecorator(ct.mainSequence);
  ct.canBePublished = sequences.length > 1 && !sequences.some(s => !s.isValid);
  return ct;
};

export const sequenceDecorator = (s: SequenceSchema): DecoratedSequenceSchema => {
  const sequence = cloneDeep(s) as DecoratedSequenceSchema;

  // don't forget to actually add all the decorated attributes here
  sequence.sequenceTouchPoints = sequence.sequenceTouchPoints.map(s =>
    sequenceTouchPointDecorator(s)
  );
  sequence.didUpdate = false;
  sequence.shouldHighlight = false;
  sequence.isContinue = sequence.terminalState === 'continue';
  sequence.isReferral = sequence.terminalState === 'referral';
  sequence.isQualified = sequence.terminalState === 'qualified';
  sequence.isNotInterested = sequence.terminalState === 'not_interested';
  sequence.isNotInterestedNow = sequence.terminalState === 'not_interested_now';
  sequence.isTerminal =
    sequence.isQualified || sequence.isNotInterested || sequence.isNotInterestedNow;
  sequence.isNotTerminal = !sequence.isTerminal;
  sequence.hasChildren = sequence.qualificationRule?.qualificationRuleItems
    ? sequence.qualificationRule.qualificationRuleItems.length > 0
    : false;
  sequence.hasFollowUpEmail = sequence.sequenceTouchPoints
    ? sequence.sequenceTouchPoints.length > 1
    : false;

  return sequence;
};

export const sequenceTouchPointDecorator = (s: SequenceTouchPointSchema) => {
  const templates: SequenceTouchPointTemplateSchema[] = s.sequenceTouchPointTemplates.map(t => ({
    ...t,
    subject: t.subject ?? '', //TODO: Remove this placeholder assignment; there's a BE bug where subsequent touchpoint subjects are returned as null when creating a document
    attachments: t.sequenceTouchPointTemplateAttachments.map(a => ({
      id: a.attachment?.id,
      attachment: a.attachment,
    })),
  }));
  const decoratedSequenceTouchPoint: DecoratedSequenceTouchPointSchema = {
    ...s,
    templates,
    isSendImmediately: s.triggerOnDuration === 0,
  };
  return decoratedSequenceTouchPoint;
};
export const sequenceEnumerator = (sequence: SequenceSchema, sequences: SequenceSchema[]): any => {
  return sequence.qualificationRule?.qualificationRuleItems?.forEach(q => {
    const decoratedSequence = sequenceDecorator(q.sequence);
    sequences.push(decoratedSequence);
    q.sequence = decoratedSequence;
    return sequenceEnumerator(q.sequence, sequences);
  });
};

export interface TopicRuleSchema extends BaseModelSchema {
  description: string;
  displayName: string;
  name: string;
  nextState: string;
  ruleType: string;
  selectable: boolean;
  teamId: number | null;

  qualificationRuleItems?: QualificationRuleItemSchema[];
}

export interface TouchPointEditorTemplateSchema {
  attachments: TouchPointEditorAttachmentInterface[];
  body: string;
  deliveryType: 'reply' | 'new';
  dynamicContents: DynamicContentSchema[];
  isActive: boolean;
  snippet: string;
  subject: string;
  aiBlocks?: AiBlockSchema[];
  id?: number;
}

export interface TouchPointEditorInterface {
  durationType: string;
  editorStatus: 'creating' | 'idle' | 'error' | 'saving';
  id: number | null;
  selectedTemplateIndex: number;
  templates: TouchPointEditorTemplateSchema[];
  triggerOnDuration: number;
}

export interface TouchPointEditorAttachmentInterface {
  id: number;
  attachment?: AttachmentSchema;
}

export interface EditorAttachmentInterface extends TouchPointEditorAttachmentInterface {}

export interface UpdateTouchPointTemplatePayload {
  data: {
    attributes: {
      attachments: { id: number }[];
      body: string;
      deliveryType: 'reply' | 'new';
      snippet: string;
      subject: string;
    };
    id: number;
    type: 'sequence_touch_point_templates';
  };
}

export interface OverwriteConversationTopicPayload {
  data: {
    attributes: {
      documentId: number;
      overwriteChildSequences: boolean;
    };
  };
}

//Below is a start of using json schemas for conversation topic
//Didn't want to reuse the old schemas as they're not using the json api spec; and it might get too convoluted trying to make them work
//Instead these are separated to their own unique schemas, think this is fine for now as it's a singular PATCH endpoint that don't really make use of the whole object
export interface OverwriteConversationTopicAPIObject {
  data: {
    attributes: OverwriteConversationTopicAttributes;
    id: string;
    type: 'conversation_topics';
  };
}
export interface OverwriteConversationTopicAttributes {
  campaigns: SimpleCampaignSchema[];
  createdAt: string;
  hasLiveVersion: boolean;
  hasUnpublishedChanges: boolean;
  name: string;
  noOfLeads: number;
  teamId: number;
  updatedAt: string;
  uuid: string;
}
export interface OverwriteConversationTopicSchema extends OverwriteConversationTopicAttributes {
  id: string;
}

export enum TopicRuleName {
  QUALIFIED = 'Qualified Positive Intent (Goal Conversion)',
  NOT_INTERESTED = 'Negative Intent',
  NOT_INTERESTED_NOW = 'Not Interested Now',
  NOT_RIGHT_PERSON = 'Not The Right Person',
  REFERRAL = 'Referral',
}
