import { datadogLogs } from '@datadog/browser-logs';
import { AlertTriangle, CheckCircle, XCircle, XClose } from '@saleswhale/barnacle/icons';
import DOMPurify from 'isomorphic-dompurify';
import { uniqueId } from 'lodash';
import LogRocket from 'logrocket';
import React, { ReactElement } from 'react';
import { ToastPosition, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { EngageAPIError } from '../../../schemas/error';
import { Button } from '../Button';
import { FeaturedIcon6DS } from '../SixSenseElements';
import './Toast.scss';

type CtaObject = {
  action: () => void;
  text: string | ReactElement;
};

interface ToastOptions {
  cta?: CtaObject;
  subLabel?: string;
  toastPosition?: ToastPosition;
}

const toastList = new Set();
const MAX_TOASTS = 3;

export function CloseToastButton({ closeToast }: { closeToast?: () => void }) {
  return (
    <Button
      className="ToastContainer__close-button"
      onClick={closeToast}
      testId="ToastContainer__closeButton"
      theme="flush"
    >
      <XClose size={20} />
    </Button>
  );
}

export function CustomToast({
  message,
  closeToast,
  cta,
  subLabel,
}: {
  closeToast: () => void;
  message: string;
  cta?: CtaObject;
  subLabel?: string;
}) {
  return (
    <div className="Toastify__contentContainer">
      <div
        className="Toastify__content"
        dangerouslySetInnerHTML={{ __html: sanitize(message) }}
        data-testid="ToastContent"
      />
      {subLabel && (
        <div className="Toastify__subLabel" data-testid="ToastSubLabel">
          {subLabel}
        </div>
      )}
      {cta && (
        <div className="Toastify__ctaContainer">
          <div
            className="Toastify__dismissButton"
            data-testid="ToastDismissButton"
            onClick={closeToast}
          >
            Dismiss
          </div>
          <div className="Toastify__ctaButton" data-testid="ToastCtaButton" onClick={cta.action}>
            {cta.text}
          </div>
        </div>
      )}
    </div>
  );
}

// TODO: tweak the timing and possibly animation
export const notify = ({
  message,
  type,
  toastPosition = 'top-right',
  cta,
  subLabel,
}: {
  message: string | { detail?: string; message?: string }[];
  type: 'positive' | 'negative' | 'warning';
  cta?: CtaObject;
  subLabel?: string;
  toastPosition?: ToastPosition;
}) => {
  // assigns a unique id to each call and set to a Set. this id is deleted when the toast is closed.
  // this ensures that extra toasts do not go into the toast queue.
  const toastId = uniqueId();
  if (toastList.size < MAX_TOASTS) {
    toastList.add(toastId);
  } else {
    toast.clearWaitingQueue();
  }

  const closeToast = () => toast.dismiss(toastId);

  const options = {
    position: toastPosition,
    hideProgressBar: true,
    onClose: () => toastList.delete(toastId),
    toastId,
  };
  let m = message;

  // Arrays are only passed in for errors
  if (Array.isArray(m)) {
    if (m[0]?.message || m[0]?.detail) {
      const t = m.map(({ message, detail }) => message || detail).join('\n');
      m = t;
    } else {
      m = 'An error occurred.';
    }
  }

  switch (type) {
    case 'positive':
      return toast.success(
        <div className="Toastify__container" data-testid="SuccessToast">
          <div className="Toastify__icon">
            <FeaturedIcon6DS color="Success" icon={CheckCircle} theme="light-circle-outline" />
          </div>
          <CustomToast closeToast={closeToast} cta={cta} message={m} subLabel={subLabel} />
        </div>,
        options
      );
    case 'negative':
      return toast.error(
        <div className="Toastify__container" data-testid="ErrorToast">
          <div className="Toastify__icon">
            <FeaturedIcon6DS color="Error" icon={XCircle} theme="light-circle-outline" />
          </div>
          <CustomToast
            closeToast={closeToast}
            cta={cta}
            message={m || 'An error occurred.'}
            subLabel={subLabel}
          />
        </div>,
        options
      );
    case 'warning':
      return toast.error(
        <div className="Toastify__container" data-testid="WarningToast">
          <div className="Toastify__icon">
            <FeaturedIcon6DS color="Warning" icon={AlertTriangle} theme="light-circle-outline" />
          </div>
          <CustomToast closeToast={closeToast} cta={cta} message={m} subLabel={subLabel} />
        </div>,
        options
      );
  }
};

const sanitize = (s: string) => DOMPurify.sanitize(s, { ALLOWED_TAGS: [], ALLOWED_ATTR: [] });

export const PositiveToast = (
  message: string | { detail?: string; message?: string }[],
  options?: ToastOptions
) => {
  notify({ message, type: 'positive', ...options });
};

// TODO: fix this typing
export const NegativeToast = (
  message: string | { detail?: string; message?: string }[] | any,
  options?: ToastOptions
) => {
  const sanitizedMessage = Array.isArray(message)
    ? message.map(m => ({
        ...m,
        message: m.message && sanitize(m.message),
        detail: m.detail && sanitize(m.detail),
      }))
    : sanitize(message);

  notify({ message: sanitizedMessage, type: 'negative', ...options });
};

export function WarningToast(
  message: string | { detail?: string; message?: string }[] | any,
  options?: ToastOptions
) {
  const sanitizedMessage = Array.isArray(message)
    ? message.map(m => ({
        ...m,
        message: m.message && sanitize(m.message),
        detail: m.detail && sanitize(m.detail),
      }))
    : sanitize(message);

  notify({ message: sanitizedMessage, type: 'warning', ...options });
}

/**
 * Convenience wrapper to throw a NegativeToast if something errors
 * @param e usually an Error
 * @param fallback fallback string, defaults to 'An error occured'
 */
export function didError(e: unknown, fallback = 'An error occured', toastOptions?: ToastOptions) {
  if (e instanceof EngageAPIError) {
    NegativeToast(e.errors, toastOptions);
    datadogLogs.logger.error(e.errors.toString(), {}, e);
    LogRocket.captureException(e);
  } else {
    NegativeToast(fallback, toastOptions);
    datadogLogs.logger.error(fallback, {}, e as Error);
    LogRocket.captureException(e as Error);
  }
}
